import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import endpoints from "../Endpoints/endpoints";
import { load } from "@cashfreepayments/cashfree-js";
import { useFormData } from "../contexts/FormDataContext";

const PaymentContext = createContext();

export const PaymentProvider = ({ children }) => {
  const [cashfree, setCashfree] = useState(null);
  const { formData } = useFormData();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const initializeSDK = async () => {
      try {
        const cashfreeInstance = await load({ mode: "production" });
        // const cashfreeInstance = await load({ mode: "sandbox" });

        setCashfree(cashfreeInstance);
      } catch (error) {
        console.error("Error initializing Cashfree SDK:", error);
      }
    };

    initializeSDK();
  }, []);
  const calculateTotal = async (
    Plan,
    adultItem,
    childItem,
    selectedSubPackage
  ) => {
    try {
      const response = await axios.post(
        `${endpoints.serverBaseURL}/api/payment/calculate-total`,
        {
          planId: Plan._id,
          adult: adultItem,
          children: childItem,
          subpackageName: selectedSubPackage.name,
        }
      );

      if (response.data.totalAmount && response.data.traceId) {
        return response.data;
      } else {
        throw new Error("Failed to calculate total amount");
      }
    } catch (error) {
      console.error("Error calculating total:", error);
      throw error;
    }
  };

  const createUser = async (
    formData,
    Plan,
    adultItem,
    childItem,
    selectedSubPackage,
    traceId
  ) => {
    try {
      const response = await axios.post(
        `${endpoints.serverBaseURL}/api/create-user`,
        {
          ...formData,
          adult: adultItem,
          children: childItem,
          planId: Plan._id,
          subpackageName: selectedSubPackage.name,
          traceId: traceId,
        }
      );
      setUser(response.data.user);
      console.log("user is", response.data.user);

      console.log("Values ", formData);
      return { userId: response.data.userId, user: response.data.user };
    } catch (error) {
      console.error("Error creating user:", error);
      throw error;
    }
  };

  const paymentStart = async (totalAmount, userId, Plan, traceId, formData) => {
    try {
      const response = await axios.post(
        `${endpoints.serverBaseURL}/api/payment/payment-start`,
        {
          userDetails: {
            userId: userId,
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
          },
          planId: Plan._id,
          totalAmount: totalAmount,
          traceId: traceId,
        }
      );
      console.log("Payment Start Response:", response.data);

      if (response.data && response.data.payment_session_id) {
        return {
          paymentSessionId: response.data.payment_session_id,
          orderId: response.data.order_id,
        };
      } else {
        throw new Error("Failed to start payment");
      }
    } catch (error) {
      console.error("Error during payment process:", error);
      throw error;
    }
  };

  const verifyPayment = async (orderId, userId, traceId) => {
    try {
      const response = await axios.post(
        `${endpoints.serverBaseURL}/api/payment/verify-payment`,
        {
          orderId,
          userId,
          traceId,
        }
      );

      if (response.data.status === "success") {
        return response.data;
      } else {
        throw new Error(
          response.data.message || "Payment verification failed."
        );
      }
    } catch (error) {
      console.error("Error verifying payment:", error);
      throw error;
    }
  };

  const confirmBooking = async (
    paymentId,
    planId,
    adult,
    children,
    subpackageName,
    userId,
    bookingDate,
    selectedSubPackage,
    traceId,
    franchiseCode
  ) => {
    try {
      const response = await axios.post(
        `${endpoints.serverBaseURL}/api/confirm-booking`,
        {
          paymentId,
          planId,
          adult,
          children,
          adultPrice: selectedSubPackage.adult_price,
          childrenPrice: selectedSubPackage.child_price,
          subpackageName,
          userId,
          bookingDate: bookingDate,
          selectedSubPackage,
          traceId,
          franchiseCode,
        }
      );
      console.log("Response.data after booking confirm is ", response.data);

      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(
          response.data.message || "Booking confirmation failed."
        );
      }
    } catch (error) {
      console.error("Error confirming booking:", error);
      throw error;
    }
  };

  return (
    <PaymentContext.Provider
      value={{
        calculateTotal,
        createUser,
        paymentStart,
        verifyPayment,
        confirmBooking,
        cashfree,
        setUser,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

export const usePayment = () => useContext(PaymentContext);
