import React from 'react';
import styles from './PrivacyPolicyPage.module.css';

const PrivacyPolicy = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Privacy Policy</h1>

      <div className={styles.policyBox}>
        <h2 className={styles.subHeader}>Bharti Resort Privacy Policy</h2>
        <p>Bharti Resort ("the Company") values your trust and follows industry best practices to ensure secure transactions and customer information privacy. This policy outlines how the Company collects, uses, and discloses your information.</p>

        <h3 className={styles.sectionTitle}>Changes to the Privacy Policy</h3>
        <p>The Company may update its Privacy Policy at any time. You are advised to review this policy periodically for any modifications. By accessing the website, www.bhartiresort.com, you agree to be bound by the terms of this Privacy Policy.</p>

        <h3 className={styles.sectionTitle}>Information We Collect</h3>
        <p>We collect two main types of information:</p>
        <ul className={styles.list}>
          <li><strong>Personal Information:</strong> Includes your name, email address, phone number, mailing address, billing information, and credit card details collected when you make a reservation, sign up for the newsletter, or contact us.</li>
          <li><strong>Usage Data:</strong> Includes your IP address, browser type, operating system, referral URL, pages you visit, and time spent on those pages. This information helps us understand how visitors use our website and improve its functionality.</li>
        </ul>

        <h3 className={styles.sectionTitle}>Use of Your Information</h3>
        <p>We use your information for various purposes, including:</p>
        <ul className={styles.list}>
          <li>Processing reservations and bookings.</li>
          <li>Sending confirmation emails and other reservation communications.</li>
          <li>Sending marketing and promotional materials.</li>
          <li>Personalizing your website experience.</li>
          <li>Improving our website and services.</li>
          <li>Complying with legal and regulatory requirements.</li>
        </ul>

        <h3 className={styles.sectionTitle}>Sharing Your Information</h3>
        <p>We may share your information with third-party service providers who assist us in operating our business and providing services to you. These service providers may be located outside of India. We will only share your information to the extent necessary for them to perform their services.</p>
        <p>We may also disclose your information if required by law or to protect the rights, property, or safety of ourselves or others.</p>

        <h3 className={styles.sectionTitle}>Your Choices</h3>
        <p>You can opt out of receiving marketing emails from us and access or update your personal information by contacting us.</p>

        <h3 className={styles.sectionTitle}>Data Security</h3>
        <p>We take reasonable steps to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no internet transmission is completely secure, and we cannot guarantee the security of your information.</p>

        <h3 className={styles.sectionTitle}>Children's Privacy</h3>
        <p>Our website is not directed to children under 18. We do not knowingly collect personal information from children under 18.</p>

        <h3 className={styles.sectionTitle}>Contact Us</h3>
        <p>If you have any questions about this Privacy Policy, please contact us at contactus@bhartiresort.com or by mail at the following address:</p>
        <p>Boriaindi Uruli Kanchan, A/p, Bori Aindi, Pune, Maharashtra 412202</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
