// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.policy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    font-size: 2em;
    color: #2a9d8f;
    text-align: center;
    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 1.5em;
    color: #264653;
    margin-top: 20px;
  }
  
  p {
    margin-bottom: 10px;
    font-size: 1em;
  }
  
  ul {
    margin-left: 20px;
    list-style-type: disc;
  }
  
  ul li {
    margin-bottom: 5px;
    font-size: 0.95em;
  }
  
  section {
    margin-bottom: 20px;
  }
  
  @media (max-width: 600px) {
    .policy-container {
      padding: 15px;
    }
  
    h1 {
      font-size: 1.8em;
    }
  
    h2 {
      font-size: 1.3em;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/CancellationPolicyPage.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;IAChB,WAAW;IACX,yBAAyB;IACzB,kBAAkB;IAClB,wCAAwC;EAC1C;;EAEA;IACE,cAAc;IACd,cAAc;IACd,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,cAAc;IACd,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;IACnB,cAAc;EAChB;;EAEA;IACE,iBAAiB;IACjB,qBAAqB;EACvB;;EAEA;IACE,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE;MACE,aAAa;IACf;;IAEA;MACE,gBAAgB;IAClB;;IAEA;MACE,gBAAgB;IAClB;EACF","sourcesContent":[".policy-container {\n    max-width: 800px;\n    margin: 0 auto;\n    padding: 20px;\n    font-family: Arial, sans-serif;\n    line-height: 1.6;\n    color: #333;\n    background-color: #f9f9f9;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  }\n  \n  h1 {\n    font-size: 2em;\n    color: #2a9d8f;\n    text-align: center;\n    margin-bottom: 20px;\n  }\n  \n  h2 {\n    font-size: 1.5em;\n    color: #264653;\n    margin-top: 20px;\n  }\n  \n  p {\n    margin-bottom: 10px;\n    font-size: 1em;\n  }\n  \n  ul {\n    margin-left: 20px;\n    list-style-type: disc;\n  }\n  \n  ul li {\n    margin-bottom: 5px;\n    font-size: 0.95em;\n  }\n  \n  section {\n    margin-bottom: 20px;\n  }\n  \n  @media (max-width: 600px) {\n    .policy-container {\n      padding: 15px;\n    }\n  \n    h1 {\n      font-size: 1.8em;\n    }\n  \n    h2 {\n      font-size: 1.3em;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
