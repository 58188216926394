// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/pages/ContactUs.css */
.contact-us-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f4f4f9;
  }
  
  .contact-info {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .contact-info h2 {
    margin-bottom: 10px;
  }
  
  .contact-info p {
    margin: 5px 0;
  }
  
  .contact-form {
    width: 100%;
    max-width: 500px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .contact-form h3 {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    color: #333;
  }
  
  .form-group input,
  .form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .submit-btn {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-btn:hover {
    background-color: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/ContactUsPage.css"],"names":[],"mappings":"AAAA,4BAA4B;AAC5B;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;EAC3B;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,WAAW;IACX,gBAAgB;IAChB,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,0CAA0C;EAC5C;;EAEA;IACE,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,kBAAkB;IAClB,WAAW;EACb;;EAEA;;IAEE,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["/* src/pages/ContactUs.css */\n.contact-us-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 20px;\n    background-color: #f4f4f9;\n  }\n  \n  .contact-info {\n    text-align: center;\n    margin-bottom: 30px;\n  }\n  \n  .contact-info h2 {\n    margin-bottom: 10px;\n  }\n  \n  .contact-info p {\n    margin: 5px 0;\n  }\n  \n  .contact-form {\n    width: 100%;\n    max-width: 500px;\n    background-color: #ffffff;\n    padding: 20px;\n    border-radius: 8px;\n    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);\n  }\n  \n  .contact-form h3 {\n    margin-bottom: 20px;\n    text-align: center;\n  }\n  \n  .form-group {\n    margin-bottom: 15px;\n  }\n  \n  .form-group label {\n    display: block;\n    margin-bottom: 5px;\n    color: #333;\n  }\n  \n  .form-group input,\n  .form-group select {\n    width: 100%;\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n  }\n  \n  .submit-btn {\n    width: 100%;\n    padding: 12px;\n    background-color: #007bff;\n    color: #fff;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n  }\n  \n  .submit-btn:hover {\n    background-color: #0056b3;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
