import React from 'react';
import './AboutUsPage.css';

const AboutUsPage = () => {
  return (
    <div className="about-us-container">
      <h1 className="main-heading">About Us</h1>
      <h2 className="tagline">Where the Sun Never Sets on Fun!</h2>
      <p className="welcome-message">
        Welcome to <strong>Bharti Resort Adventure & Amusement Park - Ravindra Bharti Resort & Holidays Pvt. Ltd.</strong> – Where the Sun Never Sets on Fun! 
        Nestled in Boriaindi near Uruli Kanchan on the Pune-Solapur Highway, our resort is the most exotic 
        destination for one-day picnics, family trips, and corporate outings.
      </p>
      <p>
        Escape the hustle and bustle of city life and immerse yourself in a world of excitement and relaxation at Bharti Resort. 
        Bringing together the vibrant essence of Goa, the serene charm of Konkan, and the lush beauty of Mahabaleshwar, our 
        resort spans over 52 acres of adventure and amusement.
      </p>
      <h3 className="highlight-heading">A Unique and Unparalleled Destination</h3>
      <p>
        Home to the largest Ferris wheel in India, Bharti Resort is a one-of-a-kind destination near Pune. 
        With a wide range of amusement and adventure activities, we promise endless fun for families, friends, and corporate groups.
      </p>
      <p>
        Surrounded by breathtaking peaks, dense vegetation, and fresh air, Bharti Resort offers top-notch amenities 
        to make your visit memorable. Our onsite restaurant serves both local and international cuisines, crafted by 
        culinary experts to tantalize your taste buds.
      </p>
      <p>
        Experience the perfect blend of luxury and adventure at Bharti Resort, your ultimate one-day picnic resort near Pune. 
        Book your visit with us and create unforgettable memories with your loved ones.
      </p>
    </div>
  );
};

export default AboutUsPage;
