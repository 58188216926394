// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Container for the whole page */
.terms-container {
  padding: 20px;
  max-width: 900px;
  margin: auto;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
}

/* Main heading style */
.terms-heading {
  font-size: 2.5em;
  color: #4CAF50;
  text-align: center;
  margin-bottom: 20px;
}

/* Introduction paragraph styling */
.terms-introduction {
  font-size: 1.2em;
  text-align: center;
  color: #555;
}

/* Subheading style */
.terms-subheading {
  font-size: 1.8em;
  margin-top: 30px;
  color: #333;
}

/* Paragraph styling */
.terms-paragraph {
  font-size: 1.1em;
  margin: 10px 0;
  padding-left: 20px;
  padding-right: 20px;
  color: #555;
}

/* Accept button styling */
.accept-button {
  display: block;
  margin: 30px auto;
  background-color: #4CAF50;
  color: white;
  padding: 15px 30px;
  font-size: 1.2em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.accept-button:hover {
  background-color: #45a049;
}

/* Thank you message styling */
.thank-you-message {
  text-align: center;
  color: green;
  font-size: 1.2em;
  font-weight: bold;
  margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/TermsAndConditionsPage.css"],"names":[],"mappings":"AAAA,iCAAiC;AACjC;EACE,aAAa;EACb,gBAAgB;EAChB,YAAY;EACZ,8BAA8B;EAC9B,gBAAgB;EAChB,WAAW;AACb;;AAEA,uBAAuB;AACvB;EACE,gBAAgB;EAChB,cAAc;EACd,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA,mCAAmC;AACnC;EACE,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;AACb;;AAEA,qBAAqB;AACrB;EACE,gBAAgB;EAChB,gBAAgB;EAChB,WAAW;AACb;;AAEA,sBAAsB;AACtB;EACE,gBAAgB;EAChB,cAAc;EACd,kBAAkB;EAClB,mBAAmB;EACnB,WAAW;AACb;;AAEA,0BAA0B;AAC1B;EACE,cAAc;EACd,iBAAiB;EACjB,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA,8BAA8B;AAC9B;EACE,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":["/* Container for the whole page */\n.terms-container {\n  padding: 20px;\n  max-width: 900px;\n  margin: auto;\n  font-family: Arial, sans-serif;\n  line-height: 1.6;\n  color: #333;\n}\n\n/* Main heading style */\n.terms-heading {\n  font-size: 2.5em;\n  color: #4CAF50;\n  text-align: center;\n  margin-bottom: 20px;\n}\n\n/* Introduction paragraph styling */\n.terms-introduction {\n  font-size: 1.2em;\n  text-align: center;\n  color: #555;\n}\n\n/* Subheading style */\n.terms-subheading {\n  font-size: 1.8em;\n  margin-top: 30px;\n  color: #333;\n}\n\n/* Paragraph styling */\n.terms-paragraph {\n  font-size: 1.1em;\n  margin: 10px 0;\n  padding-left: 20px;\n  padding-right: 20px;\n  color: #555;\n}\n\n/* Accept button styling */\n.accept-button {\n  display: block;\n  margin: 30px auto;\n  background-color: #4CAF50;\n  color: white;\n  padding: 15px 30px;\n  font-size: 1.2em;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n}\n\n.accept-button:hover {\n  background-color: #45a049;\n}\n\n/* Thank you message styling */\n.thank-you-message {\n  text-align: center;\n  color: green;\n  font-size: 1.2em;\n  font-weight: bold;\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
