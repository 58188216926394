import React from "react";
import "./Footer.css";
import { useContext } from "react";
import { PlanContext } from "../contexts/PlanContext";
import { useNavigate } from "react-router-dom";
import {AdminProfileContext} from '../contexts/AdminContext'
import SocialMediaIcons from "./NavBarComponents/SocialMediaIcons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMapMarkerAlt, faPhoneAlt,faPhone,faBuilding } from '@fortawesome/free-solid-svg-icons';
import logo from '../assests/logo/footer_logo.png';
const Footer = () => {
  const { plans } = useContext(PlanContext);
  const { adminProfile } = useContext(AdminProfileContext);
  const navigate = useNavigate();

  
  return (
    
    <footer className="footer">
  <div className="footer-content">
    {/* Logo and Contact Information Section */}
    <div className="footer-section">
      <ul>
        <li>
          <img
            className="footer_Logo"
            // src={adminProfile?.logo}
            src={logo}
            alt="logo"
            onClick={() => { navigate('/') }}
          />
          </li>
        <li>
        <FontAwesomeIcon icon={faBuilding} className="footer-icon" />
            <span>Bharti Resort Adventure & Amusement Park
            - Ravindra Bharti Resort & Holidays Pvt. Ltd.</span>
          </li>
        <li>
          <FontAwesomeIcon icon={faMapMarkerAlt} className="footer-icon" />
          <span>Boriaindi Uruli Kanchan, A/p, Bori Aindi, Pune, Maharashtra 412202</span>
        </li>
        <li>
          <FontAwesomeIcon icon={faEnvelope} className="footer-icon" />
          <span><a href="mailto:reservations@bhartiresort.com">reservations@bhartiresort.com</a></span>
        </li>
        <li>
          <FontAwesomeIcon icon={faPhoneAlt} className="footer-icon" />
          <span>+91 7570909090</span>
        </li>
      </ul>
    </div>

    {/* Plans Section */}
    <div className="footer-section">
      <h3>Plans</h3>
      <ul>
        {plans.map((plan, i) => {
          if (plan.status === 'closed') return null;
          return (
            <li key={plan._id}>
              <div onClick={() => { navigate(`/knowmore/${plan._id}`) }} className="planNameDiv">{plan.title}</div>
            </li>
          );
        })}
      </ul>
    </div>

    {/* Information Section */}
    <div className="footer-section">
      <h3>Information</h3>
      <ul>
        <li className="InfoPages" onClick={() => navigate("/aboutUs")}>About Us</li>
        <li className="InfoPages" onClick={() => navigate("/contactUs")}>Contact Us</li>
        <li className="InfoPages" onClick={() => navigate("/termsAndConditions")}>Terms & Conditions</li>
        <li className="InfoPages" onClick={() => navigate("/privacyPolicy")}>Privacy Policy</li>
        <li className="InfoPages" onClick={() => navigate("/cancellationPolicy")}>Cancellation Policy</li>
      </ul>
    </div>

    {/* Social Media Section */}
    <div className="footer-section">
      <h3>Follow Us</h3>
      <div className="social-icons">
        <SocialMediaIcons links={adminProfile?.socialMediaLinks} />
      </div>
    </div>

    {/* Google Map Section */}
    <div className="footer-section footer-map">
      <h3>Our Location</h3>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d484360.82774785947!2d74.180953!3d18.480535!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2e1015d5859b9%3A0x3045ab229e281d77!2sBharti%20Resorts%20Water%20Adventure%20%26%20Amusement%20Park!5e0!3m2!1sen!2sin!4v1723029522825!5m2!1sen!2sin"
        width="100%"
        height="200"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        title="Google Map"
      ></iframe>
    </div>
  </div>

  <div className="footer-bottom">
    <p>© 2024 Bharti Resort. All Rights Reserved.</p>
  </div>
</footer>

  );
};

export default Footer;
