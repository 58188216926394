// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Container for the entire component */
.PrivacyPolicyPage_container__pRHn- {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  
  /* Header styling */
  .PrivacyPolicyPage_header__xn7Uh {
    font-size: 28px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Scrollable box for the policy content */
  .PrivacyPolicyPage_policyBox__npgqg {
    max-height: 600px;
    overflow-y: auto;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fafafa;
    color: #555;
  }
  
  /* Subheader for sections */
  .PrivacyPolicyPage_subHeader__OZ7On {
    font-size: 22px;
    color: #444;
    margin-top: 15px;
  }
  
  /* Styling for section titles */
  .PrivacyPolicyPage_sectionTitle__OVlR4 {
    font-size: 18px;
    color: #4CAF50;
    margin-top: 15px;
  }
  
  /* List styling */
  .PrivacyPolicyPage_list__uGvD3 {
    list-style-type: disc;
    margin-left: 20px;
    color: #333;
  }
  
  /* Paragraph styling */
  .PrivacyPolicyPage_policyBox__npgqg p {
    margin-bottom: 15px;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/PrivacyPolicyPage.module.css"],"names":[],"mappings":"AAAA,uCAAuC;AACvC;IACI,aAAa;IACb,gBAAgB;IAChB,YAAY;IACZ,8BAA8B;IAC9B,gBAAgB;EAClB;;EAEA,mBAAmB;EACnB;IACE,eAAe;IACf,WAAW;IACX,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA,0CAA0C;EAC1C;IACE,iBAAiB;IACjB,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,yBAAyB;IACzB,WAAW;EACb;;EAEA,2BAA2B;EAC3B;IACE,eAAe;IACf,WAAW;IACX,gBAAgB;EAClB;;EAEA,+BAA+B;EAC/B;IACE,eAAe;IACf,cAAc;IACd,gBAAgB;EAClB;;EAEA,iBAAiB;EACjB;IACE,qBAAqB;IACrB,iBAAiB;IACjB,WAAW;EACb;;EAEA,sBAAsB;EACtB;IACE,mBAAmB;EACrB","sourcesContent":["/* Container for the entire component */\n.container {\n    padding: 20px;\n    max-width: 800px;\n    margin: auto;\n    font-family: Arial, sans-serif;\n    line-height: 1.6;\n  }\n  \n  /* Header styling */\n  .header {\n    font-size: 28px;\n    color: #333;\n    text-align: center;\n    margin-bottom: 20px;\n  }\n  \n  /* Scrollable box for the policy content */\n  .policyBox {\n    max-height: 600px;\n    overflow-y: auto;\n    padding: 15px;\n    border: 1px solid #ddd;\n    border-radius: 8px;\n    background-color: #fafafa;\n    color: #555;\n  }\n  \n  /* Subheader for sections */\n  .subHeader {\n    font-size: 22px;\n    color: #444;\n    margin-top: 15px;\n  }\n  \n  /* Styling for section titles */\n  .sectionTitle {\n    font-size: 18px;\n    color: #4CAF50;\n    margin-top: 15px;\n  }\n  \n  /* List styling */\n  .list {\n    list-style-type: disc;\n    margin-left: 20px;\n    color: #333;\n  }\n  \n  /* Paragraph styling */\n  .policyBox p {\n    margin-bottom: 15px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PrivacyPolicyPage_container__pRHn-`,
	"header": `PrivacyPolicyPage_header__xn7Uh`,
	"policyBox": `PrivacyPolicyPage_policyBox__npgqg`,
	"subHeader": `PrivacyPolicyPage_subHeader__OZ7On`,
	"sectionTitle": `PrivacyPolicyPage_sectionTitle__OVlR4`,
	"list": `PrivacyPolicyPage_list__uGvD3`
};
export default ___CSS_LOADER_EXPORT___;
