// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-us-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    color: #333;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .main-heading {
    font-size: 2.5rem;
    color: #007b5e;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .tagline {
    font-size: 1.5rem;
    color: #ff6b35;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .welcome-message {
    font-size: 1.2rem;
    font-weight: bold;
    color: #555;
    text-align: justify;
  }
  
  .highlight-heading {
    font-size: 1.8rem;
    color: #007b5e;
    margin-top: 20px;
    text-align: center;
  }
  
  p {
    font-size: 1rem;
    color: #555;
    text-align: justify;
    margin-bottom: 15px;
    /* text-align: center; */
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/AboutUsPage.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,yBAAyB;IACzB,WAAW;IACX,8BAA8B;IAC9B,gBAAgB;IAChB,kBAAkB;IAClB,wCAAwC;EAC1C;;EAEA;IACE,iBAAiB;IACjB,cAAc;IACd,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,cAAc;IACd,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,iBAAiB;IACjB,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,cAAc;IACd,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,WAAW;IACX,mBAAmB;IACnB,mBAAmB;IACnB,wBAAwB;EAC1B","sourcesContent":[".about-us-container {\n    max-width: 800px;\n    margin: 0 auto;\n    padding: 20px;\n    background-color: #f8f9fa;\n    color: #333;\n    font-family: Arial, sans-serif;\n    line-height: 1.6;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  }\n  \n  .main-heading {\n    font-size: 2.5rem;\n    color: #007b5e;\n    text-align: center;\n    margin-bottom: 10px;\n  }\n  \n  .tagline {\n    font-size: 1.5rem;\n    color: #ff6b35;\n    text-align: center;\n    margin-bottom: 20px;\n  }\n  \n  .welcome-message {\n    font-size: 1.2rem;\n    font-weight: bold;\n    color: #555;\n    text-align: justify;\n  }\n  \n  .highlight-heading {\n    font-size: 1.8rem;\n    color: #007b5e;\n    margin-top: 20px;\n    text-align: center;\n  }\n  \n  p {\n    font-size: 1rem;\n    color: #555;\n    text-align: justify;\n    margin-bottom: 15px;\n    /* text-align: center; */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
