import React, { useState } from 'react';
import './TermsAndConditionsPage.css';

const TermsAndConditionsPage = () => {
  const [accepted, setAccepted] = useState(false);

//   const handleAccept = () => {
//     setAccepted(true);
//     // Perform any action upon acceptance, like redirecting the user
//   };

  return (
    <div className="terms-container">
      <h1 className="terms-heading">Terms and Conditions</h1>
      <p className="terms-introduction">
        User agrees to adhere to the terms and conditions ("Terms of Use") relating to the use of www.bhartiresort.com. ("Website")
      </p>

      <h2 className="terms-subheading">Website Information:</h2>
      <p className="terms-paragraph">
        All information available on the Website is provided without any liability
        whatsoever to Bharti Resort Adventure & Amusement Park - Ravindra Bharti Resort
        & Holidays Pvt. Ltd. (Formally known as Ravindra Bharti Aviation Pvt. Ltd.).
        The information on this Website is merely to provide information on the
        present facilities and activities at the Adventure Park and the rules and
        regulations prescribed for use of the facilities made available therein.
      </p>

      <p className="terms-paragraph">
        This Website has been created for providing general information regarding the
        recreational and amusement park facilities, including bookings, fees, operations, 
        and terms of use. It is not intended to serve as an official or legal document, 
        and the accuracy, completeness, or suitability of the content provided is not 
        guaranteed.
      </p>
      
      <h2 className="terms-subheading">Website Use:</h2>
      <p className="terms-paragraph">
        By accessing the Website, users agree to comply with these Terms of Use. 
        If users do not agree to these terms, they should discontinue using the site 
        immediately. Bharti Resort reserves the right to modify or update these Terms 
        and Conditions at any time without prior notice. It is the responsibility of 
        the user to stay informed about any changes.
      </p>
      
      <h2 className="terms-subheading">Management Reserve All the Rights:</h2>
      <p className="terms-paragraph">
        All information containing trademarks, copyrights, including text, graphics, 
        interface, logos, design, frames, sounds, music, drawings, expression, etc., 
        is the property of Bharti Resort Adventure & Amusement Park - Ravindra Bharti 
        Resort & Holidays Pvt. Ltd. and in no event shall its disclosure be interpreted 
        as granting the User a license or right to use any of it.
      </p>

      <p className="terms-paragraph">
        Bharti Resort reserves the right to modify, add, or remove content, restrict 
        access, and terminate or suspend user access at any time. The use of any content 
        from the website for commercial purposes is prohibited without express written 
        consent.
      </p>

      <h2 className="terms-subheading">Liability Disclaimer:</h2>
      <p className="terms-paragraph">
        The website and its content are provided “as is,” without any representations 
        or warranties of any kind. Bharti Resort makes no representations or warranties 
        regarding the accuracy, completeness, or fitness for a particular purpose of 
        the information on the website. In no event will Bharti Resort be liable for 
        any direct, indirect, incidental, special, consequential, or punitive damages 
        resulting from the use of or inability to use the website, even if Bharti Resort 
        has been advised of the possibility of such damages.
      </p>

      <h2 className="terms-subheading">Links to Other Websites:</h2>
      <p className="terms-paragraph">
        The website may contain links to external websites that are not under the control 
        of Bharti Resort. We are not responsible for the content or availability of such 
        external sites. Links to external websites are provided solely for your convenience 
        and do not imply endorsement of the linked sites.
      </p>

      <h2 className="terms-subheading">Booking and Reservation Policy:</h2>
      <p className="terms-paragraph">
        All bookings made through the website are subject to availability. Prices for 
        services are subject to change without notice. Users must provide accurate 
        information when booking services or making inquiries. The resort reserves the 
        right to cancel any booking that violates these terms or is found to have inaccurate 
        or fraudulent information.
      </p>

      <h2 className="terms-subheading">Cancellation and Refund Policy:</h2>
      <p className="terms-paragraph">
        Users may cancel bookings by contacting customer support as per the terms 
        outlined during the booking process. Refunds, if applicable, will be issued 
        based on the specific terms and conditions provided at the time of booking. 
        Bharti Resort reserves the right to adjust refund amounts based on operational 
        costs incurred.
      </p>

      <h2 className="terms-subheading">Conduct and Behavior:</h2>
      <p className="terms-paragraph">
        Users agree to abide by all rules and regulations while using the resort facilities, 
        including safety measures, conduct guidelines, and any other policies in place. 
        Bharti Resort reserves the right to remove individuals who engage in inappropriate 
        or unlawful behavior.
      </p>

      <h2 className="terms-subheading">Governing Law:</h2>
      <p className="terms-paragraph">
        These Terms and Conditions shall be governed by and construed in accordance with 
        the laws of India. Any dispute arising out of or related to the use of this website 
        or the services provided shall be subject to the exclusive jurisdiction of the courts 
        in [Insert City, State].
      </p>

      <h2 className="terms-subheading">Acceptance of Terms:</h2>
      <p className="terms-paragraph">
        By using this Website, you acknowledge that you have read and understood these Terms 
        and Conditions, and agree to be bound by them. If you do not agree to these terms, 
        you should refrain from using the Website.
      </p>

      {/* Accept button and message
      {!accepted ? (
        <button onClick={handleAccept} className="accept-button">
          Accept Terms and Conditions
        </button>
      ) : (
        <p className="thank-you-message">Thank you for accepting the Terms and Conditions!</p>
      )} */}
    </div>
  );
};

export default TermsAndConditionsPage;
