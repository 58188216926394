import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import './CheckoutBill.css';

const CheckoutBill = ({ total=0 }) => {
  const GST_RATE = 0.18; // 18% GST rate
  const gst = total * GST_RATE;
  const grandTotal = total + gst;

  return (
    <Box className="bg-primary-gradient">
      <Box className="checkout-bill-row">
        <Typography variant="subtitle1">Total</Typography>
        <Typography variant="subtitle1">₹{total.toFixed(2)}</Typography>
      </Box>
      <Box className="checkout-bill-row">
        <Typography variant="subtitle1">GST (18%)</Typography>
        <Typography variant="subtitle1">₹{gst.toFixed(2)}</Typography>
      </Box>
      <Divider className="checkout-divider" />
      <Box className="checkout-bill-row">
        <Typography variant="subtitle1" className="grand-total">Grand Total</Typography>
        <Typography variant="subtitle1" className="grand-total">₹{grandTotal.toFixed(2)}</Typography>
      </Box>
    </Box>
  );
};

export default CheckoutBill;
