import React from 'react';
import './CancellationPolicyPage.css';

const CancellationPolicy = () => {
  return (
    <div className="policy-container">
      <h1>Reservation and Cancellation Policy</h1>

      <section>
        <h2>Reservation</h2>
        <p>Please make advance reservations via telephone or online to ensure room availability. Advance payment is mandatory for a confirmed reservation. Please read the cancellation policy before confirming.</p>
        <ul>
        <li>Please make advance reservation via telephone or book online to ensure room availability.</li>
    <li>Advance payment in cash, deposit, or online is mandatory to obtain a confirmed reservation. Please read the cancellation policy before confirming your reservation.</li>
    <li>Rates displayed online are not guaranteed until the confirmation voucher is generated. Rates are generally dynamic in nature. Once a rate is selected and the transaction is executed, no other rate is applicable, and no refund is admissible.</li>
    <li>The estimated cost for stay includes the confirmed room rate, estimated taxes, and estimated fees.</li>
    <li>Actual taxes, fees, and cess at the time of stay shall apply, which may vary from estimates. You are liable to pay any extra cost due to such changes.</li>
    <li>Currency conversions are estimates only. The bill at checkout will be in the hotel’s local currency.</li>
    <li>The hotel reserves the right to modify or cancel a reservation if it appears that a guest is engaged in fraudulent, illegal, or inappropriate activity or if the reservation was obtained fraudulently, with malafide intention, or by mistake.</li>
    <li>Upon check-in, please present the hotel reservation confirmation voucher along with valid photo ID or passport and a valid credit card for any incidental or extra charges.</li>
    <li>All promotional/sale rates are restrictive fares and non-refundable. Some rates do not allow changes in the booking. Check restrictions on the rate while booking.</li>
    <li>All extra services & amenities not part of this offer will be available at an additional charge.</li>
    <li>This offer cannot be combined with any other offer/promotion or benefit(s) available through any loyalty program run by Bharti Resort Adventure & Amusement Park - Ravindra Bharti Resort & Holidays Pvt. Ltd. (Formally known as Ravindra Bharti Aviation Pvt. Ltd.) or any third party.</li>
    <li>In case of non-availability of pre-booked room at the time of arrival, the hotel will offer an alternate similar standard hotel/ in a similar room type, at its discretion and without any further liability.</li>
    <li>Rates may change without notice and may vary for special events except for confirmed reservation against the advance payment.</li>
    <li>All rates are subject to prevailing local taxes as applicable on room tariff & services opted.</li>
        </ul>
      </section>
      <section>
        <h2>Occupancy</h2>
        <li>Normal occupancy allows a maximum of two people per room. Additional persons (if room size permits) are charged extra as per prevalent rate of the Hotel.</li>
      </section>

      <section>
        <h2>Deposit/Online Payment</h2>
        <li>Advance deposit/online payment is required, covering the total room nights booked for the entire stay, to confirm the reservation.</li>
        <li>We accept payments via Maestro, Visa, Master/Rupe Card, and UPI. For alternate payment methods, please contact reservations.</li>
        <li>Corporate reservations must be secured with a company credit card. For alternate payment methods, please contact reservations.</li>
        <li>Group bookings of four or more rooms require cancellation notice for deposit refunds as per the cancellation & early checkout policy.</li>
      </section>

      <section>
        <h2>Cancellation and Early Checkout Policy</h2>
        <ul>
            <li>In case your plans change, ensure to inform in writing the cancellation/amendments in plan to the Hotel Reservation for a refund of your deposit. The guidelines in this regard are as follows:</li>
            <li>The modification/cancellation request for bookings made online will be processed automatically through the Internet Booking Engine. Any refund due shall be done through the system in accordance with the applicable modification/cancellation rules.</li>
            <li>In case of rebooking, a rate difference may be payable for the change as per the rate applicable for the rebooked date/room type and subject to availability.</li>
            <li>Any additional charges or rate difference for the modified reservation can be paid through the same payment mode/card as used for original reservation.</li>
            <li>Please note that if an online booking is modified or cancelled with the hotel’s reservations office/Hotel/Resort’s sales office, it cannot be further modified or cancelled online.</li>
            <li>The refund of the reservation done by the Travel Agent will be processed by the issuing Travel Agent only and no refund admissible online.</li>
            <li>In the event of cancellation of non-cancellable booking(s), the client shall not be refunded the amount paid towards making the reservation(s).</li>
            <li>Certain room rates & types have restrictions and without benefits. In case you do not wish to opt for restricted rates & types, you can book the room(s) available on www.namastedwaar.com without benefits/restrictions.</li>
            <li>In case of early checkout, you are liable to pay for the entire confirmed reservation. If not sure of the policy, contact the hotel for clarification.</li>
            <li>Please note for reservation of more than one room, in that case all rooms are determined as “reserved nights”. For example, two (2) rooms reserved for four (4) nights would be (8) reserved nights; hence, for such (8) reserved nights, the applicable cancellation period is 72 hours.</li>
            <li>Cancellation policy based on reserved room nights: <ui><li>4 reserved nights - 48 hours prior to arrival</li>
            <li>4-9 reserved nights - 72 hours prior to arrival</li>
            <li>9+ reserved nights - 7 days prior to arrival</li></ui></li>
        </ul>

      </section>

      <section>
        <h2>Refund Policy</h2>
        <li>On cancellation of refundable booking the refund processing will take time between two(2)-to four(4) weeks to show refund back on your credit card statement.</li>
        <li>Reasons for the specified processing time are based on billing cycle of your credit card company and processing time of the bank.</li>
        <li>The refund depends on numerous factors such as the hotel’s cancellation policy, time of cancellation and processing fees etc. For more details refer to the cancellation policy mentioned above.</li>
        <li>In case the reservation is not confirmed, we will not charge you anything on your credit card and release the whole amount if any that was held on it immediately. Now after we do this, it will still take two(2) to four(4) weeks for the bank to process the refund and to show the refunded amount on your credit card.</li>
      </section>

      <section>
        <h2>Check-in and Check-out</h2>
        <li>
        Normal check-in time is 1:00 PM. For late arrivals, please call for instructions on the procedure for arrival after 6 PM.</li>
        <li>Check-out time is 11 AM.</li>
        <li>Early check-in and check-out are available by prior arrangement, subject to availability and prior confirmation.</li>
      </section>

      <section>
        <h2>Modification and Non-Arrival</h2>
        <li>Any modification in the reservation is subject to availability at the time of request.</li>
      </section>

      <section>
        <h2>Children and Infants</h2>
        <li>Children up to 5 years can stay free with parents without an extra bed.</li>
        <li>Children aged 5-11 years will be charged extra.</li>
        <li>Children above 12 years of age will be treated as young adults and the prevalent reservation charges will be applicable and levied.</li>
      </section>

      <section>
        <h2>Non-Arrival to the Hotel (No Show)</h2>
        <li> If you fail to arrive at the hotel on the arrival date the entire reservation will be cancelled automatically by the hotels and will be charged for the entire reservation.</li>
        <li>If you fail to check in on the first date but still continue your travel plan to stay at the hotel, please, urgently, contact us to keep the room for you for the rest of the nights. Otherwise as mentioned above, the entire reservation will be auto-cancelled and no refund admissible.
        </li>
      </section>

      <section>
        <h2>Shortened Stay  (Early Check-out)</h2>
        <li>Shortened stay is subject to entire period charge whether or not you stay the entire period. If you know the change in your plan, please contact us at the earliest to minimize the charge by the hotel subject to cancellation policy.</li>
      </section>

      <section>
        <h2>Special Requests</h2>
        <li>Please note that Hotel neither guarantees nor liable to admit any request for amendment in plan or any other special requests which shall be admissible subject to availability on arrival.</li>
        <li>The request for airport pick-up requires intimation to the reservation at the time of the room(s) booking.</li>
      </section>

      <section>
        <h2>Confirmation Voucher</h2>
        <li>
        The reservation once confirmed, a confirmation number and the voucher is generated that you can print for your convenience.</li>
        <li>It is necessary to present the confirmation voucher to the hotel on arrival. On failure to produce the confirmation voucher may result in the hotel not honoring your reservation unless the hotel is able to trace & match the excess in the reservation system</li>

      </section>

      <section>
        <h2>Payment Security</h2> 
        <li>It is important to know that whenever you provide the personal details or credit card information, it is secured. Your credit card number, name, address, and telephone number are protected by the latest security technology.</li>      
        </section>
    </div>
  );
};

export default CancellationPolicy;
